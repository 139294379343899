import { OrgRequired } from '../../components/Organization';
import ProgramMgmt from '../../components/Program';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  return (
    <PublicHomeLayout>
      <OrgRequired>
        {(org) => <ProgramMgmt org={org} withLayout routePrefix='' />}
      </OrgRequired>
    </PublicHomeLayout>
  );
}
